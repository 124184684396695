import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingFees: [],
    };
  },
  computed: {
    ...mapFields("fee", {
      feeIds: "allIds",
      feeIdsCached: "cacheAllIds",
    }),
    ...mapGetters("fee", {
      findFee: "find",
    }),
  },
  methods: {
    ...mapActions("fee", {
      retrieveFee: "load"
    }),
    resolveFee(itemId, force = false) {
      if (force || (!this.isFeeLoaded(itemId) && !this.loadingFees.includes(itemId))) {
        this.loadingFees.push(itemId);
        this.retrieveFee(decodeURIComponent(itemId));
      }

      return this.findFee(itemId);
    },
    resolveFees(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }

      return itemIds.map((itemId) => {
        return this.resolveFee(itemId);
      }).filter(e => e);
    },
    refreshFees(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      itemIds.forEach(itemId => this.resolveFee(itemId, true));
    },
    getFeeItem(feeIri, type) {
      if (feeIri !== null) {
        let fee = this.resolveFee(feeIri);
        if (fee) {
          return fee[type];
        }
      }
      return '';
    },
    isFeeLoaded(itemId) {
      return this.feeIdsCached.includes(itemId);
    },
    isFeeSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isFeeLoaded(itemId));
    },
    resolveFeeAsync(itemId) {
      const poll = resolve => {
        if (this.isFeeLoaded((itemId))) {
          resolve(this.resolveFee(itemId));
        } else {
          this.resolveFee(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveFeesAsync(itemIds) {
      const poll = resolve => {
        if (this.isFeeSetLoaded((itemIds))) {
          resolve(this.resolveFees(itemIds));
        } else {
          this.resolveFee(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
