<template>
  <v-dialog v-model="showFeeDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <FeeForm
          :errors="violations"
          :values="item"
          :loading="isLoading"
          :tenants="tenantObjects"
          ref="feeDialogForm"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showFeeDialog = false">{{ $t('Close') }}</v-btn>
        <v-spacer/>
        <v-btn class="mainAccent lighten-3" dark @click="resetForm">{{ $t('Reset') }}</v-btn>
        <v-btn color="primary" @click="sendForm">{{ $t('Submit') }}</v-btn>
      </v-card-actions>
    </v-card>
    <Loading :visible="isLoading"/>
  </v-dialog>
</template>

<script>
//import {createHelpers} from 'vuex-map-fields';
import {mapFields} from 'vuex-map-fields';
import FeeForm from './Form';
import NotificationMixin from '../../mixins/NotificationMixin';
import Loading from "@/components/Loading";
import {mapActions, mapGetters} from "vuex";
import TenantMixin from "@/mixins/TenantMixin";

const servicePrefix = 'Fee';

export default {
  name: "FeeDialog",
  servicePrefix,
  mixins: [NotificationMixin, TenantMixin],
  components: {
    Loading,
    FeeForm
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      required: true
    },
    handleCreated: {
      type: Function,
      required: false
    },
    handleUpdated: {
      type: Function,
      required: false
    },
    feeItem: {
      type: Object,
      required: false,
    },
    tenantUsers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: {},
      createMessage: this.$t("successfullyCreated"),
      updateMessage: this.$t("successfullyUpdated"),
    }
  },
  computed: {
    ...mapFields('fee', ['error', 'isLoading', 'created', 'violations', 'updated', 'byId']),
    ...mapGetters('fee', ['find']),
    showFeeDialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    tenantObjects() {
      let tenantUserIds = [];
      this.tenantUsers.forEach(tenantUser => {
        tenantUserIds.push(tenantUser.tenant);
      });
      return tenantUserIds.map((itemId) => {
        return this.resolveTenant(itemId);
      }).filter(e => e);
    }
  },
  methods: {
    ...mapActions('fee', {
      create: 'create',
      update: 'update',
      resetData: 'resetData',
      retrieve: 'load'
    }),
    ...mapActions('tenant', {
      retrieveTenant: 'load',
    }),
    sendForm() {
      if (this.isValidForm()) {
        let model = this.$refs.feeDialogForm.$v.item.$model;
        if (this.handleUpdated && model['@id']) {
          this.update(model);
        }
        if (this.handleCreated && !model['@id']) {
          this.create(model);
        }
      }
    },
    reset() {
      this.$refs.feeDialogForm.$v.$reset();
    },
    resetForm() {
      this.item = {...this.feeItem};
      if (this.$refs['feeDialogForm']) {
        this.$refs.feeDialogForm.$v.$reset();
      }
    },
    isValidForm() {
      this.$refs.feeDialogForm.$v.$touch();
      return !this.$refs.feeDialogForm.$v.$invalid;
    },
  },
  watch: {
    feeItem() {
      this.item = {...this.feeItem};
      this.resetForm();
    },
    created(created) {
      if (!created) {
        return;
      }
      this.showMessage(this.createMessage);
      if (this.handleCreated) {
        this.handleCreated(this.created);
        this.showFeeDialog = false;
      }
    },
    updated() {
      this.showMessage(this.updateMessage);
      if (this.handleUpdated) {
        this.handleUpdated(this.updated);
        this.showFeeDialog = false
      }
    },
    error(message) {
      message && this.showError(message);
    },
  },
  created() {
    this.item = {...this.feeItem};
  }
}
</script>
