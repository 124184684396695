<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-select
            :error-messages="tenantErrors"
            :label="$t('FeeTenant')"
            @blur="$v.item.tenant.$touch()"
            @input="$v.item.tenant.$touch()"
            :items="tenants"
            v-model="item.tenant"
            item-value="@id"
            item-text="name"
            required
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            :error-messages="unitErrors"
            :label="$t('FeeUnit')"
            @blur="$v.item.unit.$touch()"
            @input="$v.item.unit.$touch()"
            required
            :items="unitValues"
            v-model="item.unit"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="minParticipantsRequired"
            :error-messages="minParticipantsErrors"
            :label="$t('feeMinParticipants')"
            :hint="$t('feeMinParticipantsHelp')"
            @blur="$v.item.minParticipants.$touch()"
            @input="$v.item.minParticipants.$touch()"
            v-model.number="item.minParticipants"
            type="number"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="rateErrors"
            :label="$t('FeeRate')"
            required
            v-model.number="item.rate"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required, requiredIf} from 'vuelidate/lib/validators';

export default {
  name: 'FeeForm',
  mixins: [validationMixin],
  components: {},
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
    tenants: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      unit: null,
      rate: null,
      speaker: null,
      unitValues: [
        {text: this.$t('day'), value: 'day'},
        {text: this.$t('hour'), value: 'hour'},
        {text: this.$t('feePerParticipant'), value: 'perParticipant'},
      ],
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    minParticipantsRequired() {
      return this.item.unit === 'perParticipant';
    },
    // region validations
    tenantErrors() {
      const errors = [];
      if (!this.$v.item.tenant.$dirty) {
        return errors;
      }

      has(this.violations, 'tenant') && errors.push(this.violations.tenant);

      !this.$v.item.tenant.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.item.unit.$dirty) {
        return errors;
      }

      has(this.violations, 'unit') && errors.push(this.violations.unit);

      !this.$v.item.unit.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    minParticipantsErrors() {
      const errors = [];
      if (!this.$v.item.minParticipants.$dirty) {
        return errors;
      }
      has(this.violations, 'minParticipants') && errors.push(this.violations.minParticipants);
      !this.$v.item.minParticipants.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    rateErrors() {
      const errors = [];
      if (!this.$v.item.rate.$dirty) {
        return errors;
      }

      has(this.violations, 'rate') && errors.push(this.violations.rate);

      !this.$v.item.rate.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    violations() {
      return this.errors || {};
    }
    //endregion
  },
  methods: {
    log(data) {
      console.log(data);
    },
  },
  watch: {
    minParticipantsRequired() {
      if (!this.minParticipantsRequired) {
        this.item.minParticipants = null;
      }
    }
  },
  validations: {
    item: {
      unit: {
        required,
      },
      tenant: {
        required,
      },
      rate: {
        required,
      },
      minParticipants: {
        required: requiredIf(function () {
          return this.minParticipantsRequired;
        })
      },
      speaker: {}
    }
  },
  created() {
    if ('undefined' === typeof this.item.speaker && this.hasRole('ROLE_SPEAKER')) {
      this.item.speaker = this.getUserIri()
    }
  }
}
</script>
