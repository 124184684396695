import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  data() {
    return {
      loadingSeminars: []
    };
  },
  computed: {
    ...mapFields('seminar', {
      seminarIds: 'allIds',
      seminarIdsCached: 'cacheAllIds'
    }),
    ...mapGetters('seminar', {
      findSeminar: 'find'
    })
  },
  methods: {
    ...mapActions('seminar', { retrieveSeminar: 'load' }),
    resolveSeminar(itemId) {
      if (!this.isSeminarLoaded(itemId) && !this.loadingSeminars.includes(itemId)) {
        this.loadingSeminars.push(itemId);
        this.retrieveSeminar(decodeURIComponent(itemId));
      }
      return this.findSeminar(itemId);
    },
    resolveSeminars(itemIds) {
      if (typeof itemIds === 'undefined') {
        return [];
      }
      return itemIds
        .map((itemId) => {
          return this.resolveSeminar(itemId);
        })
        .filter((e) => e);
    },
    getSeminarItem(seminarIri, type) {
      if (seminarIri !== null) {
        let seminar = this.resolveSeminar(seminarIri);
        if (seminar) {
          return seminar[type];
        }
      }
      return '';
    },
    isSeminarLoaded(itemId) {
      return this.seminarIdsCached.includes(itemId);
    },
    isSeminarSetLoaded(itemIds) {
      return itemIds.every((itemId) => this.isSeminarLoaded(itemId));
    },
    resolveSeminarAsync(itemId) {
      const poll = (resolve) => {
        if (this.isSeminarLoaded(itemId)) {
          resolve(this.resolveSeminar(itemId));
        } else {
          this.resolveSeminar(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      };
      return new Promise(poll);
    },
    resolveSeminarsAsync(itemIds) {
      const poll = (resolve) => {
        if (this.isSeminarSetLoaded(itemIds)) {
          resolve(this.resolveSeminars(itemIds));
        } else {
          this.resolveSeminar(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      };
      return new Promise(poll);
    },
    getCombinedObject(existingSeminar = null) {
      let combinedObject = {
        topics: null,
        name: null,
        number: null,
        timeSlot: {
          start: null,
          end: null
        },
        teachingUnits: null,
        speaker: null,
        coSpeaker: null,
        fee: null,
        maxParticipants: null,
        seminarContent: null,
        additionalConfirmationText: null,
        offer: null,
        noOnline: false,
        presenceSeminar: false,
        venue: null,
        externalMeeting: {
          joinUrl: null,
          title: null,
          password: null
        },
        attendanceListTemplate: '/attendance_list_templates/DEFAULT',
        addons: []
      };

      if (existingSeminar) {
        combinedObject.topics = existingSeminar.topics;
        combinedObject.name = existingSeminar.name;
        combinedObject.number = existingSeminar.number;
        combinedObject.presenceSeminar = existingSeminar.presenceSeminar;
        combinedObject.offer = existingSeminar.offer;
        combinedObject.venue = existingSeminar.venue;
        combinedObject.seminarConfig = existingSeminar.seminarConfig;
        combinedObject.attendanceListTemplate = existingSeminar.attendanceListTemplate;
        combinedObject.timeSlot = existingSeminar.timeSlot;
        combinedObject.teachingUnits = existingSeminar.teachingUnits;
        combinedObject.addons = existingSeminar.addons;
        combinedObject.speaker = existingSeminar.speaker;
        combinedObject.coSpeaker = existingSeminar.coSpeaker;
        combinedObject.fee = existingSeminar.fee;
        combinedObject.seminarConfig = existingSeminar.seminarConfig;
        combinedObject.maxParticipants = existingSeminar.maxParticipants;
        combinedObject.seminarContent = existingSeminar.seminarContent;
        combinedObject.additionalConfirmationText = existingSeminar.additionalConfirmationText;
        combinedObject.externalMeeting = existingSeminar.externalMeeting
          ? existingSeminar.externalMeeting
          : { joinUrl: null, title: null, password: null };
      }

      return combinedObject;
    }
  }
};
